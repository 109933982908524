<template>
  <div class="max-w-screen-xl w-full flex flex-col items-center mx-auto">
    <h1 class="heading">Build Customer Lists.</h1>
    <LoadingSpinner v-if="loading" />
    <template v-else>
      <div class="w-full flex flex-row mb-4 justify-between">
        <Button class="ml-auto" @click.prevent="showCreateCustomerListModal = true">
          Create List
        </Button>
      </div>
      <table class="w-full min-w-[70%] table-fixed border border-slate-300 text-sm">
        <thead>
          <tr>
            <th class="border border-slate-300" @click="updateOrdering('name')">
              List Name
              <span v-if="currentOrdering === 'name'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-name'" class="sort-indicator">▼</span>
              <span v-if="currentOrdering !== '-name' && currentOrdering !== 'name'">▲▼</span>
            </th>
            <th class="border border-slate-300" @click="updateOrdering('type')">
              List Type
              <span v-if="currentOrdering === 'type'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-type'" class="sort-indicator">▼</span>
              <span v-if="currentOrdering !== '-type' && currentOrdering !== 'type'">▲▼</span>
            </th>
            <th class="border border-slate-300" @click="updateOrdering('contact__name')">
              External Stakeholder
              <span v-if="currentOrdering === 'contact__name'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-contact__name'" class="sort-indicator">▼</span>
              <span
                v-if="currentOrdering !== '-contact__name' && currentOrdering !== 'contact__name'"
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('company__name')">
              Company
              <span v-if="currentOrdering === 'company__name'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-company__name'" class="sort-indicator">▼</span>
              <span
                v-if="currentOrdering !== '-company__name' && currentOrdering !== 'company__name'"
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('datetime_created')">
              Date Begin Build
              <span v-if="currentOrdering === 'datetime_created'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-datetime_created'" class="sort-indicator">▼</span>
              <span
                v-if="
                  currentOrdering !== '-datetime_created' && currentOrdering !== 'datetime_created'
                "
                >▲▼</span
              >
            </th>
            <th class="border border-slate-300" @click="updateOrdering('sent_to_salesforce')">
              Sent To SFDC?
              <span v-if="currentOrdering === 'sent_to_salesforce'" class="sort-indicator">▲</span>
              <span v-if="currentOrdering === '-sent_to_salesforce'" class="sort-indicator">▼</span>
              <span
                v-if="
                  currentOrdering !== '-sent_to_salesforce' &&
                  currentOrdering !== 'sent_to_salesforce'
                "
                >▲▼</span
              >
            </th>
            <th
              class="border border-slate-300"
              @click="updateOrdering('datetime_set_salesforce_send_to_app')"
            >
              Date Created in SFDC
              <span
                v-if="currentOrdering === 'datetime_set_salesforce_send_to_app'"
                class="sort-indicator"
                >▲</span
              >
              <span
                v-if="currentOrdering === '-datetime_set_salesforce_send_to_app'"
                class="sort-indicator"
                >▼</span
              >
              <span
                v-if="
                  currentOrdering !== '-datetime_set_salesforce_send_to_app' &&
                  currentOrdering !== 'datetime_set_salesforce_send_to_app'
                "
                >▲▼</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="list in lists.list" :key="list.id">
            <td>
              <router-link
                class="text-cyan-500"
                :to="{ name: 'InternalCustomerListEdit', params: { id: list.id } }"
              >
                {{ list.name }}
              </router-link>
            </td>
            <td>{{ list.type }}</td>
            <td>{{ list.contact ? list.contact.name : 'N/A' }}</td>
            <td>{{ list.company ? list.company.name : 'N/A' }}</td>
            <td>{{ list.datetimeCreatedMoment.format('MM/DD/YYYY') }}</td>
            <td>{{ list.sentToSalesforce ? '✅' : '❌' }}</td>
            <td>
              {{
                list.datetimeSetSalesforceSendToAppMoment.isValid()
                  ? list.datetimeSetSalesforceSendToAppMoment.format('MM/DD/YYYY')
                  : '-'
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!lists.list.length" class="py-8">No W.I.P. Customer Lists.</div>
    </template>
    <CollectionPagination
      :class="{ hidden: loading || !lists.list.length }"
      :collection="lists"
      @is-loading-status-update="(isLoading) => (loading = isLoading)"
    />
  </div>
  <CreateInternalCustomerListModal
    v-if="showCreateCustomerListModal"
    @close="showCreateCustomerListModal = false"
  />
</template>

<script>
import InternalCustomerList from '@/services/internalCustomerLists'

import LoadingSpinner from '@/components/LoadingSpinner'
import CollectionPagination from '@/components/inputs/CollectionPagination'
import Button from '@/components/inputs/Button'
import CreateInternalCustomerListModal from '@/components/modals/CreateInternalCustomerListModal'

export default {
  name: 'InternalCustomerListIndex',
  components: { LoadingSpinner, CollectionPagination, Button, CreateInternalCustomerListModal },
  data() {
    return {
      showCreateCustomerListModal: false,
      loading: true,
      lists: InternalCustomerList.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters: {
          ordering: this.$route.query.ordering || '-datetime_created',
        },
      }),
    }
  },
  async created() {
    await this.lists.refresh()
    this.loading = false
  },
  methods: {
    setPaginationQueryParams() {
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.lists.pagination.page,
          pageSize: this.lists.pagination.size,
          ordering: this.lists.filters.ordering,
        },
      })
    },
    updateOrdering(ordering) {
      let currentOrdering = this.$route.query.ordering || '-datetime_created'
      if (currentOrdering === ordering) {
        ordering = '-' + ordering
      }
      this.$router.push({
        name: this.$route.name,
        query: {
          page: 1, // Reset to first page
          pageSize: this.lists.pagination.size,
          ordering,
        },
      })
    },
  },
  computed: {
    currentOrdering() {
      return this.$route.query.ordering || '-datetime_created'
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.loading = true
        this.lists.filters.ordering = this.$route.query.ordering || '-datetime_created'
        this.lists.pagination.page = this.$route.query.page || 1
        this.lists.pagination.size = this.$route.query.pageSize || 20
        await this.lists.refresh()
        this.loading = false
      },
    },
    'lists.pagination.page': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'lists.pagination.size': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'lists.filters.ordering': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
  },
}
</script>

<style scoped>
th,
td {
  padding: 0.5rem;
}
</style>
