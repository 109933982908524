<template>
  <Modal @close="$emit('close')" classes="max-w-3xl">
    <h1 class="heading font-bold mb-6">Create Customer List.</h1>
    <InternalCustomerListForm
      :disabled="attemptingCreate"
      @submit="attemptCreateInternalCustomerList"
    />
  </Modal>
</template>

<script>
import _ from 'lodash'
import InternalCustomerList from '@/services/internalCustomerLists'

import Modal from '@/components/modals/Modal'
import InternalCustomerListForm from '@/components/InternalCustomerListForm'

export default {
  name: 'CreateInternalCustomerListModal',
  components: {
    Modal,
    InternalCustomerListForm,
  },
  data() {
    return {
      attemptingCreate: false,
    }
  },
  methods: {
    attemptCreateInternalCustomerList(name, type, contactId, companyId) {
      this.attemptingCreate = true
      InternalCustomerList.api
        .create(name, type, contactId, companyId)
        .then(({ id }) =>
          this.$router.push({
            name: 'InternalCustomerListEdit',
            params: { id },
          }),
        )
        .finally(() => (this.attemptingCreate = false))
    },
  },
}
</script>
